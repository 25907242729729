import { Fragment, useEffect, useState, useCallback } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { useDispatch } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridCellParams
} from '@material-ui/data-grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinkIcon from '@material-ui/icons/Link'
import Box from '@material-ui/core/Box'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

import Navbar from 'components/Navbar'
import CustomNoRowsOverlay from 'components/CustomNoRowsOverlay'
import CustomSearch from 'components/CustomSearch'
import CustomAlert from 'components/CustomAlert'
import EditWordDialog from 'components/EditWordDialog'
import WordHistoryDialog from 'components/WordHistoryDialog'
import GridCellExpand from 'components/GridCellExpand'
import StatusActions from 'components/StatusActions'
import UploadBlendDialog from 'components/UploadBlendDialog'
import AnimationDialog from 'components/AnimationDialog'

import { getUserRole } from 'utils/role'
import { getUpdatedAt } from 'utils/time'
import { useCategory } from 'utils/hook'
import { posTh } from 'utils/partOfSpeech'
import { RequestMethod } from 'types/api'
import { WordList, UpdatedLog } from 'types/wordList'
import { USER_ROLE } from 'types/userRole'
import { WORD_STATUS } from 'types/wordStatus'
import { CategoryResponse } from 'types/category'
import { Token } from 'types/token'
import { loadingStart, loadingStop } from 'redux/loading/action'
import { API_WORD, BLENDER_FILE_URL } from 'env'
import {
  PageContainer,
  TableWrapper,
  SearchWrapper,
  Link,
  StatusLabel,
} from './style'

interface RowObj extends UpdatedLog {
  id: number
  word: string
  POS: string
  meaning: string
  video_url: string
  blender_url: string
}

type LanguageSupported = keyof CategoryResponse

/*there are three variables for storing rows info
  backupRows - store the original response data
  originalRows - store the rows info after modified field (for being a original source of search component)
  rows - store the rows info after modified field
*/
let backupRows: WordList[] = []
let originalRows: RowObj[] = []

const WordListPage = () => {
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(50)
  const [rows, setRows] = useState<RowObj[]>([])
  const category = useCategory()
  const { i18n } = useTranslation()
  const { user } = useAuth0()
  const userRole = getUserRole(user);

  const fetchData = useCallback(
    async (unmounted: boolean, source: CancelTokenSource) => {
      dispatch(loadingStart())
      const config = {
        method: RequestMethod.GET,
        url: API_WORD,
        cancelToken: source.token,
      }

      try {
        const response = await axios(config)
        const data = response.data.result
        const rowObj = cloneDeep(data)

        //get latest updated_log
        rowObj.forEach((row: any) => {
          row.status = row.update_log[0].status
          row.comment = row.update_log[0].comment
          row.updated_at = new Date(row.update_log[0].updated_at + 'Z')
          row.updated_by = row.update_log[0].updated_by
          delete row.update_log
        })
        if (!unmounted) {
          backupRows = data
          originalRows = rowObj
          setRows(rowObj)
          dispatch(loadingStop())
        }
      } catch (error) {
        if (!unmounted) {
          console.error('error', error)
          dispatch(loadingStop())
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  /*When come to the WordList page, check user role and fetch all words */
  useEffect(() => {
    let unmounted = false
    let source = axios.CancelToken.source()
    fetchData(unmounted, source)
    return () => {
      unmounted = true
      source.cancel('Cancelling in cleanup')
    }
  }, [fetchData])

  const renderAction = (params: GridCellParams) => {
    const row = params.row
    const word = row.word
    const TokenWord: Token = {
      original_word: word,
      word_id: row.id,
      POS: row.POS,
    }
    const lookups = backupRows.find((row) => row.id === params.row.id)  
    
    return (
      <Box display="flex" justifyContent="space-around">
        <div>
          <AnimationDialog word={TokenWord} />
        </div>
        <div>
          <EditWordDialog
            id={row.id}
            word={row.word}
            pos={row.POS}
            meaning={row.meaning}
            category={row.category}
            variant={row.variant}
          />
        </div>
        <div>
          <UploadBlendDialog id={row.id} />
        </div>
        <div>
          <WordHistoryDialog history={lookups?.update_log} />
        </div>
      </Box>
    )
  }

  const renderCategory = (params: GridCellParams) => {
    const row = params.row
    const lang = i18n.language
    const currentCategory = category.find(
      (option) => option.id === row.category
    )
    if (currentCategory)
      return <p>{currentCategory[lang as LanguageSupported]}</p>
    else return <CircularProgress />
  }

  const renderPOS = (params: GridCellParams) => {
    const row = params.row
    const posObj = posTh.find((el) => el.value === row.POS)
    return <p>{posObj?.label}</p>
  }

  const renderStatusActions = (id: number, status: WORD_STATUS) => {
    switch (userRole) {
      case USER_ROLE.ADMIN:
      case USER_ROLE.SIGN_LANGUAGE_INTERPRETER:
        return <StatusActions wordId={id} status={status} />
      default:
        return <div></div>
    }
  }

  const renderStatus = (params: GridCellParams) => {
    const row = params.row
    return (
      <Box display="flex">
        <Box>
          <StatusLabel status={row.status}>{row.status}</StatusLabel>
        </Box>
        <Box alignContent="flex-end">
          {renderStatusActions(row.id, row.status)}
        </Box>
      </Box>
    )
  }

  const renderDate = (params: GridCellParams) => {
    const date = params.row.updated_at    
    
    if (date) {
      const txt = getUpdatedAt(date)
      return <p>{txt}</p>
    } else return <div></div>
  }

  const renderCellExpand = (params: GridCellParams) => {
    return (
      <GridCellExpand
        value={params.value ? params.value.toString() : ''}
        width={params.colDef.width!}
      />
    )
  }

  const renderBlender = (params: GridCellParams) => {
    const blender = params.row.blender_url

    if (blender)
      return (
        <Link href={`${BLENDER_FILE_URL}/${blender}`}>
          <InsertDriveFileIcon />
        </Link>
      )
    else return <div></div>
  }

  const renderVideoUrl = (params: GridCellParams) => {
    const link = params.row.video_url
    if (link)
      return (
        <Link href={link} target="_blank" rel="noopener noreferrer">
          <LinkIcon />
        </Link>
      )
    else return <div></div>
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'category',
      headerName: 'Category',
      width: 120,
      renderCell: renderCategory,
    },
    {
      field: 'word',
      headerName: 'Word',
      width: 120,
      renderCell: renderCellExpand,
    },
    { field: 'variant', headerName: 'Variant', width: 120 },
    {
      field: 'POS',
      headerName: 'Part of Speech',
      width: 120,
      renderCell: renderPOS,
    },
    {
      field: 'meaning',
      headerName: 'Meaning',
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: renderStatus,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: renderAction,
      width: 200,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      field: 'updated_at',
      headerName: 'Modified',
      width: 200,
      renderCell: renderDate,
    },
    {
      field: 'updated_by',
      headerName: 'Modified by',
      width: 120,
      renderCell: renderCellExpand,
    },
    { field: 'blender_url', headerName: 'Blender', renderCell: renderBlender },
    {
      field: 'video_url',
      headerName: 'Video',
      width: 120,
      renderCell: renderVideoUrl,
    },
  ]

  const handleSearch = (result: any[]) => {
    setRows(result)
  }

  const handlePageSizeChange = (pageSize: number, _details?: any) => {
    setPageSize(pageSize)
  }

  return (
    <Fragment>
      <Navbar />
      <CustomAlert />
      <PageContainer>
        <SearchWrapper>
          <CustomSearch source={originalRows} onSearch={handleSearch} />
        </SearchWrapper>
        <TableWrapper>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {style: {gap: "20px"}},
            }}
            onPageSizeChange={handlePageSizeChange}
            pageSize={pageSize}
            disableSelectionOnClick
            autoHeight
          />
        </TableWrapper>
      </PageContainer>
    </Fragment>
  )
}

export default WordListPage
