import { createStore, applyMiddleware, Middleware } from 'redux'
import { persistStore } from 'redux-persist'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducer from './root-reducer'

let middlewares: Middleware<any, any, any>[] = []
middlewares.push(thunk)
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger as Middleware<any, any, any>)
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares))
export const persistor = persistStore(store)
