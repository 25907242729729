import { useState, ChangeEvent, Fragment } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import Navbar from 'components/Navbar'
import CustomAlert from 'components/CustomAlert'
import CustomProgressButton from 'components/CustomProgressButton'
import WordForm from 'components/WordForm'
import { showAlert } from 'redux/alert/action'
import { RequestMethod } from 'types/api'
import { API_WORD } from 'env'
import {
  Container,
  FormContainer,
  ButtonContainer,
  Title,
  ErrorText,
} from './styles'

const initialState = {
  word: '',
  pos: '',
  meaning: '',
  category: -1,
  variant: '',
}

const AddWordPage = () => {
  const [wordInfo, setWordInfo] = useState(initialState)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()
  const { word, pos, meaning, category, variant } = wordInfo

  const handleChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const { value, name } = target
    if (error) setError('')
    if (name === 'category' && typeof value === 'string')
      setWordInfo({ ...wordInfo, [name]: parseInt(value) })
    else setWordInfo({ ...wordInfo, [name]: value })
  }
  
  const handleSubmit = async () => {
    if (word && pos && meaning && category !== -1) {
      setLoading(true)
      try {
        const accessToken = await getAccessTokenSilently()
        const config = {
          method: RequestMethod.POST,
          url: API_WORD,
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
          },
          data: {
            word: word,
            POS: pos,
            meaning: meaning,
            category: category,
            variant: variant || '',
          },
        }
        const res = await axios(config)
        if (res.status === 201) {
          setLoading(false)
          dispatch(showAlert('Add new word success', 'success'))
          setWordInfo(initialState)
          setError('')
        }
      } catch (error) {
        const message = axios.isAxiosError(error) ? error.response?.data.error : error;
        setLoading(false)
        dispatch(showAlert(message || 'Error! Please try again.', 'error'))
      }
    } else {
      setError('Please fill in every forms')
    }
  }

  return (
    <Fragment>
      <Navbar />
      <CustomAlert />
      <Container>
        <Title>add new word</Title>
        <FormContainer>
          <WordForm wordInfo={wordInfo} onChange={handleChange} />
        </FormContainer>
        <ButtonContainer>
          <ErrorText>{error}</ErrorText>
          <CustomProgressButton loading={loading} onClick={handleSubmit}>
            Submit
          </CustomProgressButton>
        </ButtonContainer>
      </Container>
    </Fragment>
  )
}

export default AddWordPage
