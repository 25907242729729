import axios from 'axios'
import { Dispatch } from 'redux'
import isEmpty from 'lodash/isEmpty'
import {
  TokenizationActionTypes,
  TOKENIZE_SUCCESS,
  TOKENIZE_FAILURE,
  CLEAR_TOKENIZE,
  TOKENIZE_START,
} from 'redux/tokenization/types'
import { showAlert } from 'redux/alert/action'
import { addChip, stateCaptionWrapper } from 'redux/captionInput/action'
import { RequestMethod } from 'types/api'
import { Token } from 'types/token'
import { API_TOKENIZATION } from 'env'

export const tokenizeStart = (): TokenizationActionTypes => ({
  type: TOKENIZE_START,
})

export const tokenizeSuccess = (
  token: Array<Token>,
  index: number
): TokenizationActionTypes => ({
  type: TOKENIZE_SUCCESS,
  payload: token,
  index: index,
})

export const tokenizeFailure = (err: string): TokenizationActionTypes => ({
  type: TOKENIZE_FAILURE,
  payload: err,
})

export const clearTokenizeWord = (): TokenizationActionTypes => ({
  type: CLEAR_TOKENIZE,
})

export const wordSegmentation = (sentence: string, index: number) => {
  return async (dispatch: Dispatch) => {
    const sentenceToSend = sentence.trim()
    if (isEmpty(sentenceToSend)) return
    dispatch(tokenizeStart())
    try {
      const tokenizedConfig = {
        method: RequestMethod.POST,
        url: API_TOKENIZATION,
        data: {
          sentence: sentenceToSend,
        },
      }
      const response = await axios(tokenizedConfig)
      const data = response.data.result
      data.forEach((word: any) => {
        if (word.hasOwnProperty('alternative')) {
          word.alternative.unshift({
            original_word: word.original_word,
            POS: word.POS || 'ไม่ระบุ',
            meaning: word.meaning || null,
            word_id: word.word_id || null,
          })
        }
      })
      stateCaptionWrapper('', index)
      dispatch(tokenizeSuccess(data, index))
      dispatch(addChip(data, index))
    } catch (err) {
      const message = axios.isAxiosError(err) ? err?.response?.data.error : err;
      dispatch(
        showAlert(message || 'Tokenization Failure. Please try again', 'error')
      )
      dispatch(tokenizeFailure(message || 'tokenize action error'))
    }
  }
}
